var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-inner"
  }, [_c('b-table', {
    ref: "table_docs",
    staticClass: "table-docs",
    attrs: {
      "id": "table-docs",
      "fields": _vm.fields,
      "items": _vm.items,
      "busy": _vm.table_is_busy,
      "responsive": true,
      "empty-filtered-text": "Нет документов",
      "show-empty": ""
    },
    on: {
      "row-clicked": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center mt-2"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', {
          staticClass: "center bold mt-2"
        }, [_vm._v("Загрузка...")])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.get_select_all
          },
          on: {
            "input": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-status",
          class: {
            success: data.item.fixed
          }
        }), data.value ? _c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "checked": _vm.get_selected_arr,
            "value": data.value
          },
          on: {
            "input": _vm.setSelect
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell(supplierDocument)",
      fn: function (data) {
        var _data$item$supplierDo, _data$item$supplierDo2;

        return [_c('div', {
          staticClass: "b-date"
        }, [_vm._v(_vm._s((_data$item$supplierDo = data.item.supplierDocument) === null || _data$item$supplierDo === void 0 ? void 0 : _data$item$supplierDo.id))]), _c('div', {
          staticClass: "b-date date mt-1 table-substr"
        }, [_vm._v(" " + _vm._s(data.item.supplierDocument ? _vm.convertDate((_data$item$supplierDo2 = data.item.supplierDocument) === null || _data$item$supplierDo2 === void 0 ? void 0 : _data$item$supplierDo2.date) : '–') + " ")])];
      }
    }, {
      key: "cell(date)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-date"
        }, [_vm._v(_vm._s(data.item.number))]), _c('div', {
          staticClass: "b-date date mt-1 table-substr"
        }, [_vm._v(" " + _vm._s(data.item.date ? _vm.convertDate(data.item.date) : '') + " ")])];
      }
    }, {
      key: "cell(branch)",
      fn: function (data) {
        var _data$item$branch, _data$item$branch2, _data$item, _data$item$branch3, _data$item$branch3$en, _data$item$branch3$en2, _data$item2, _data$item2$branch, _data$item2$branch$en, _data$item2$branch$en2, _data$item3, _data$item3$branch, _data$item3$branch$en, _data$item3$branch$en2, _data$item4, _data$item4$branch, _data$item4$branch$en, _data$item4$branch$en2;

        return [_c('div', {
          staticClass: "b-supplier name"
        }, [_vm._v(" " + _vm._s((_data$item$branch = data.item.branch) !== null && _data$item$branch !== void 0 && _data$item$branch.name ? (_data$item$branch2 = data.item.branch) === null || _data$item$branch2 === void 0 ? void 0 : _data$item$branch2.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info table-substr"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_data$item = data.item) !== null && _data$item !== void 0 && (_data$item$branch3 = _data$item.branch) !== null && _data$item$branch3 !== void 0 && (_data$item$branch3$en = _data$item$branch3.entities) !== null && _data$item$branch3$en !== void 0 && (_data$item$branch3$en2 = _data$item$branch3$en[0]) !== null && _data$item$branch3$en2 !== void 0 && _data$item$branch3$en2.inn ? (_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$branch = _data$item2.branch) === null || _data$item2$branch === void 0 ? void 0 : (_data$item2$branch$en = _data$item2$branch.entities) === null || _data$item2$branch$en === void 0 ? void 0 : (_data$item2$branch$en2 = _data$item2$branch$en[0]) === null || _data$item2$branch$en2 === void 0 ? void 0 : _data$item2$branch$en2.inn : '–') + "/ " + _vm._s((_data$item3 = data.item) !== null && _data$item3 !== void 0 && (_data$item3$branch = _data$item3.branch) !== null && _data$item3$branch !== void 0 && (_data$item3$branch$en = _data$item3$branch.entities) !== null && _data$item3$branch$en !== void 0 && (_data$item3$branch$en2 = _data$item3$branch$en[0]) !== null && _data$item3$branch$en2 !== void 0 && _data$item3$branch$en2.kpp ? (_data$item4 = data.item) === null || _data$item4 === void 0 ? void 0 : (_data$item4$branch = _data$item4.branch) === null || _data$item4$branch === void 0 ? void 0 : (_data$item4$branch$en = _data$item4$branch.entities) === null || _data$item4$branch$en === void 0 ? void 0 : (_data$item4$branch$en2 = _data$item4$branch$en[0]) === null || _data$item4$branch$en2 === void 0 ? void 0 : _data$item4$branch$en2.kpp : '–') + " ")])])];
      }
    }, {
      key: "cell(targetBranch)",
      fn: function (data) {
        var _data$item$targetBran, _data$item$targetBran2, _data$item5, _data$item5$targetBra, _data$item5$targetBra2, _data$item5$targetBra3, _data$item6, _data$item6$targetBra, _data$item6$targetBra2, _data$item6$targetBra3, _data$item7, _data$item7$targetBra, _data$item7$targetBra2, _data$item7$targetBra3, _data$item8, _data$item8$targetBra, _data$item8$targetBra2, _data$item8$targetBra3;

        return [_c('div', {
          staticClass: "b-supplier name"
        }, [_vm._v(" " + _vm._s((_data$item$targetBran = data.item.targetBranch) !== null && _data$item$targetBran !== void 0 && _data$item$targetBran.name ? (_data$item$targetBran2 = data.item.targetBranch) === null || _data$item$targetBran2 === void 0 ? void 0 : _data$item$targetBran2.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_data$item5 = data.item) !== null && _data$item5 !== void 0 && (_data$item5$targetBra = _data$item5.targetBranch) !== null && _data$item5$targetBra !== void 0 && (_data$item5$targetBra2 = _data$item5$targetBra.entities) !== null && _data$item5$targetBra2 !== void 0 && (_data$item5$targetBra3 = _data$item5$targetBra2[0]) !== null && _data$item5$targetBra3 !== void 0 && _data$item5$targetBra3.inn ? (_data$item6 = data.item) === null || _data$item6 === void 0 ? void 0 : (_data$item6$targetBra = _data$item6.targetBranch) === null || _data$item6$targetBra === void 0 ? void 0 : (_data$item6$targetBra2 = _data$item6$targetBra.entities) === null || _data$item6$targetBra2 === void 0 ? void 0 : (_data$item6$targetBra3 = _data$item6$targetBra2[0]) === null || _data$item6$targetBra3 === void 0 ? void 0 : _data$item6$targetBra3.inn : '–') + "/ " + _vm._s((_data$item7 = data.item) !== null && _data$item7 !== void 0 && (_data$item7$targetBra = _data$item7.targetBranch) !== null && _data$item7$targetBra !== void 0 && (_data$item7$targetBra2 = _data$item7$targetBra.entities) !== null && _data$item7$targetBra2 !== void 0 && (_data$item7$targetBra3 = _data$item7$targetBra2[0]) !== null && _data$item7$targetBra3 !== void 0 && _data$item7$targetBra3.kpp ? (_data$item8 = data.item) === null || _data$item8 === void 0 ? void 0 : (_data$item8$targetBra = _data$item8.targetBranch) === null || _data$item8$targetBra === void 0 ? void 0 : (_data$item8$targetBra2 = _data$item8$targetBra.entities) === null || _data$item8$targetBra2 === void 0 ? void 0 : (_data$item8$targetBra3 = _data$item8$targetBra2[0]) === null || _data$item8$targetBra3 === void 0 ? void 0 : _data$item8$targetBra3.kpp : '–') + " ")])])];
      }
    }, {
      key: "cell(supplier)",
      fn: function (data) {
        var _data$item$supplier, _data$item$supplier2, _data$item$supplier3, _data$item$supplier4, _data$item$supplier5, _data$item$supplier6;

        return [_c('div', {
          staticClass: "b-supplier name cursor"
        }, [_vm._v(" " + _vm._s((_data$item$supplier = data.item.supplier) !== null && _data$item$supplier !== void 0 && _data$item$supplier.full_name ? (_data$item$supplier2 = data.item.supplier) === null || _data$item$supplier2 === void 0 ? void 0 : _data$item$supplier2.full_name : (_data$item$supplier3 = data.item.supplier) !== null && _data$item$supplier3 !== void 0 && _data$item$supplier3.name ? (_data$item$supplier4 = data.item.supplier) === null || _data$item$supplier4 === void 0 ? void 0 : _data$item$supplier4.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info table-substr"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_data$item$supplier5 = data.item.supplier) !== null && _data$item$supplier5 !== void 0 && _data$item$supplier5.inn ? data.item.supplier.inn : '–') + "/ " + _vm._s((_data$item$supplier6 = data.item.supplier) !== null && _data$item$supplier6 !== void 0 && _data$item$supplier6.kpp ? data.item.supplier.kpp : '–') + " ")])])];
      }
    }, {
      key: "cell(sum)",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(data.value) || 0) + " ₽ ")])];
      }
    }, {
      key: "cell(retail_sum)",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(data.value) || 0) + " ₽ ")])];
      }
    }, {
      key: "cell(retailSum)",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(data.value ? _vm.formatPrice(data.value) : '0.00') + " ₽ ")])];
      }
    }, {
      key: "cell(operation)",
      fn: function (data) {
        var _data$item$operation, _data$item$operation2, _data$item$operation3, _data$item$operation4, _data$item$operation5;

        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [_vm.page_type === 'movement' ? [_vm._v(" " + _vm._s(data.item.name ? data.item.name : '–') + " ")] : [((_data$item$operation = data.item.operation) === null || _data$item$operation === void 0 ? void 0 : _data$item$operation.name) === 'income' ? _c('span', [_vm._v("Приход")]) : ((_data$item$operation2 = data.item.operation) === null || _data$item$operation2 === void 0 ? void 0 : _data$item$operation2.name) === 'return' ? _c('span', [_vm._v(" Возврат ")]) : ((_data$item$operation3 = data.item.operation) === null || _data$item$operation3 === void 0 ? void 0 : _data$item$operation3.name) === 'write_off' ? _c('span', [_vm._v(" Списание ")]) : ((_data$item$operation4 = data.item.operation) === null || _data$item$operation4 === void 0 ? void 0 : _data$item$operation4.name) === 'cashless_sale' ? _c('span', [_vm._v(" Безналичная продажа ")]) : ((_data$item$operation5 = data.item.operation) === null || _data$item$operation5 === void 0 ? void 0 : _data$item$operation5.name) === 'cash_sale' ? _c('span', [_vm._v(" Наличная продажа ")]) : _c('span', [_vm._v("Не указано")])]], 2)];
      }
    }, {
      key: "cell(edo_status)",
      fn: function (_ref) {
        var _vm$edo_status$item$e, _item$edo, _vm$edo_status$item$e2, _item$edo2, _vm$edo_status$item$e3, _item$edo3;

        var item = _ref.item;
        return [_c('div', [_c('div', {
          staticClass: "edo-status",
          style: {
            color: (_vm$edo_status$item$e = _vm.edo_status[(_item$edo = item.edo) === null || _item$edo === void 0 ? void 0 : _item$edo[0].status]) === null || _vm$edo_status$item$e === void 0 ? void 0 : _vm$edo_status$item$e.color,
            background: (_vm$edo_status$item$e2 = _vm.edo_status[(_item$edo2 = item.edo) === null || _item$edo2 === void 0 ? void 0 : _item$edo2[0].status]) === null || _vm$edo_status$item$e2 === void 0 ? void 0 : _vm$edo_status$item$e2.background
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.showEgaisModal(item);
            }
          }
        }, [_vm._v(" " + _vm._s((_vm$edo_status$item$e3 = _vm.edo_status[(_item$edo3 = item.edo) === null || _item$edo3 === void 0 ? void 0 : _item$edo3[0].status]) === null || _vm$edo_status$item$e3 === void 0 ? void 0 : _vm$edo_status$item$e3.text) + " ")])])];
      }
    }, {
      key: "cell(owner)",
      fn: function (data) {
        var _data$item$owner, _data$item$owner2;

        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [data.item.owner ? [_vm._v(" " + _vm._s((_data$item$owner = data.item.owner) === null || _data$item$owner === void 0 ? void 0 : _data$item$owner.first_name) + " " + _vm._s((_data$item$owner2 = data.item.owner) === null || _data$item$owner2 === void 0 ? void 0 : _data$item$owner2.last_name) + " ")] : [_vm._v("–")]], 2)];
      }
    }, {
      key: "cell(fixed)",
      fn: function (data) {
        return [_c('b-badge', {
          class: {
            success: data.item.fixed
          }
        }, [_vm._v(" " + _vm._s(data.item.fixed ? 'Проведен' : 'Черновик') + " ")])];
      }
    }, {
      key: "cell(comment)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.comment ? data.item.comment : '-') + " ")];
      }
    }, {
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, {
      key: "table-caption",
      fn: function () {
        return [_vm.items_total > 0 ? _c('div', {
          staticClass: "p-2"
        }, [_vm._v(" Всего " + _vm._s(_vm.items_total) + " ")]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }