<template>
  <div class="table-inner">
    <b-table
      id="table-docs"
      ref="table_docs"
      :fields="fields"
      :items="items"
      :busy="table_is_busy"
      :responsive="true"
      empty-filtered-text="Нет документов"
      show-empty
      class="table-docs"
      @row-clicked="rowClick"
    >
      <template #table-busy>
        <div class="text-center mt-2">
          <b-spinner
            variant="primary"
            label="Загрузка..."
          />
          <div class="center bold mt-2">Загрузка...</div>
        </div>
      </template>

      <template #head(id)>
        <b-form-checkbox
          :checked="get_select_all"
          @input="changeSelectAll"
        />
      </template>

      <template #cell(id)="data">
        <div
          class="b-status"
          :class="{ success: data.item.fixed }"
        ></div>
        <b-form-checkbox
          v-if="data.value"
          :checked="get_selected_arr"
          class="cursor"
          :value="data.value"
          @input="setSelect"
        />
      </template>

      <template #cell(supplierDocument)="data">
        <div class="b-date">{{ data.item.supplierDocument?.id }}</div>
        <div class="b-date date mt-1 table-substr">
          {{ data.item.supplierDocument ? convertDate(data.item.supplierDocument?.date) : '–' }}
        </div>
      </template>

      <template #cell(date)="data">
        <div class="b-date">{{ data.item.number }}</div>
        <div class="b-date date mt-1 table-substr">
          {{ data.item.date ? convertDate(data.item.date) : '' }}
        </div>
      </template>

      <template #cell(branch)="data">
        <div class="b-supplier name">
          {{ data.item.branch?.name ? data.item.branch?.name : 'Не указан' }}
        </div>
        <div class="b-supplier info table-substr">
          ИНН/КПП:
          <span class="ml-2">
            {{ data.item?.branch?.entities?.[0]?.inn ? data.item?.branch?.entities?.[0]?.inn : '–' }}/
            {{ data.item?.branch?.entities?.[0]?.kpp ? data.item?.branch?.entities?.[0]?.kpp : '–' }}
          </span>
        </div>
      </template>

      <template #cell(targetBranch)="data">
        <div class="b-supplier name">
          {{ data.item.targetBranch?.name ? data.item.targetBranch?.name : 'Не указан' }}
        </div>
        <div class="b-supplier info">
          ИНН/КПП:
          <span class="ml-2">
            {{ data.item?.targetBranch?.entities?.[0]?.inn ? data.item?.targetBranch?.entities?.[0]?.inn : '–' }}/
            {{ data.item?.targetBranch?.entities?.[0]?.kpp ? data.item?.targetBranch?.entities?.[0]?.kpp : '–' }}
          </span>
        </div>
      </template>

      <template #cell(supplier)="data">
        <div class="b-supplier name cursor">
          {{
            data.item.supplier?.full_name
              ? data.item.supplier?.full_name
              : data.item.supplier?.name
              ? data.item.supplier?.name
              : 'Не указан'
          }}
        </div>
        <div class="b-supplier info table-substr">
          ИНН/КПП:
          <span class="ml-2">
            {{ data.item.supplier?.inn ? data.item.supplier.inn : '–' }}/
            {{ data.item.supplier?.kpp ? data.item.supplier.kpp : '–' }}
          </span>
        </div>
      </template>

      <template #cell(sum)="data">
        <span class="d-flex float-right"> {{ formatPrice(data.value) || 0 }} ₽ </span>
      </template>

      <template #cell(retail_sum)="data">
        <span class="d-flex float-right"> {{ formatPrice(data.value) || 0 }} ₽ </span>
      </template>

      <template #cell(retailSum)="data">
        <span class="d-flex float-right"> {{ data.value ? formatPrice(data.value) : '0.00' }} ₽ </span>
      </template>

      <template #cell(operation)="data">
        <span class="d-flex float-left">
          <template v-if="page_type === 'movement'">
            {{ data.item.name ? data.item.name : '–' }}
          </template>
          <template v-else>
            <span v-if="data.item.operation?.name === 'income'">Приход</span>
            <span v-else-if="data.item.operation?.name === 'return'"> Возврат </span>
            <span v-else-if="data.item.operation?.name === 'write_off'"> Списание </span>
            <span v-else-if="data.item.operation?.name === 'cashless_sale'"> Безналичная продажа </span>
            <span v-else-if="data.item.operation?.name === 'cash_sale'"> Наличная продажа </span>
            <span v-else>Не указано</span>
          </template>
        </span>
      </template>

      <template #cell(edo_status)="{ item }">
        <div>
          <div
            class="edo-status"
            :style="{
              color: edo_status[item.edo?.[0].status]?.color,
              background: edo_status[item.edo?.[0].status]?.background
            }"
            @click.stop="showEgaisModal(item)"
          >
            {{ edo_status[item.edo?.[0].status]?.text }}
          </div>
        </div>
      </template>
      <template #cell(owner)="data">
        <span class="d-flex float-left">
          <template v-if="data.item.owner">
            {{ data.item.owner?.first_name }} {{ data.item.owner?.last_name }}
          </template>
          <template v-else>–</template>
        </span>
      </template>

      <template #cell(fixed)="data">
        <b-badge :class="{ success: data.item.fixed }">
          {{ data.item.fixed ? 'Проведен' : 'Черновик' }}
        </b-badge>
      </template>

      <template #cell(comment)="data">
        {{ data.item.comment ? data.item.comment : '-' }}
      </template>

      <template #empty="scope">
        <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
      </template>

      <template #table-caption>
        <div
          v-if="items_total > 0"
          class="p-2"
        >
          Всего {{ items_total }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    props: {
      fields: {
        type: Array,
        default: () => []
      },

      items: {
        type: Array,
        default: () => []
      },

      items_total: {
        type: Number,
        default: 0
      },

      table_is_busy: {
        type: Boolean,
        default: true
      },

      page_type: {
        type: String,
        default: ''
      },
      get_select_all: {
        type: Boolean,
        default: false
      },
      get_selected_arr: {
        type: Array,
        default: () => []
      },
      set_select_all: {
        type: Function
      },
      set_selected: {
        type: Function
      }
    },

    data: function () {
      return {
        edo_status: {
          awaiting: {
            background: '#FFFAE7',
            color: '#D4A600',
            text: 'Отправлен'
          },
          clarified: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Уточнён'
          },
          awaiting_clarification: {
            background: '#FFEBEE',
            color: '#E53835',
            text: 'Требуется уточнение'
          },
          signed: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Подписан'
          },
          rejected: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Отменён'
          },
          awaitng_egais_confirm: {
            background: '#FFFAE7',
            color: '#D4A600',
            text: 'Акт выслан в ЕГАИС'
          },
          egais_confirmed: {
            background: '#EBF9F5',
            color: '#00CB91',
            text: 'Акт зарег. в ЕГАИС'
          },
          draft: {
            background: '#efefef',
            color: '#888888',
            text: 'Не подтвержден'
          },
          awaiting_repeal: {
            background: '#FFFAE7',
            color: '#D4A600',
            text: 'Подтверждение отмены'
          },
          awaiting_reject: {
            background: '#FFFAE7',
            color: '#D4A600',
            text: 'Ожидание отмены ТТН'
          }
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    mounted() {
      this.clearCurrentDocument()
    },

    methods: {
      ...mapActions({
        clearCurrentDocument: 'operprocess/clearCurrentDocument'
      }),
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      changeSelectAll(value) {
        if (!value) {
          this.set_selected([])
          this.set_select_all(value)
          return
        }
        const newSelectedArr = []
        this.items.forEach((item) => {
          const id = item.id
          if (!this.get_selected_arr.includes(id)) newSelectedArr.push(id)
        })
        this.set_selected(newSelectedArr)
        this.set_select_all(value)
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      rowClick(item) {
        this.$router.push({
          name: `operation-process.overhead.${this.page_type}.document.edit`,
          params: { id: item.id }
        })
      },
      setSelect(data) {
        this.$emit('select')
        this.set_selected(data)
      },
      showEgaisModal(head) {
        this.$emit('showEgaisModal', head)
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .badge {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;

    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
  }
  :deep .table-docs {
    min-height: calc(100%) !important;
    padding-bottom: 0 !important;
    max-height: 0 !important;
    th.center:not(.id):not(:nth-child(7)) {
      position: relative;
      div {
        display: flex;
        float: left;
      }
      .b-status {
        width: 2px;
        height: 32px;
        position: absolute;
        left: 0px;
        top: 50%;
        background: #bcbcbc;
        border-radius: 0px 1px 1px 0px;
        transform: translate(0%, -50%);

        &.success {
          background: #00cb91;
        }
      }
    }
    table {
      border-top: none;
      border-collapse: separate;
      border-spacing: 0;
      tbody {
        tr:first-child {
          th {
            border-top: none;
          }
        }
      }
      tr:not(:nth-child(1)) {
        td {
          border-top: 1px solid #e1e1e1 !important;
        }
      }
    }
    tr {
      cursor: pointer;
    }
    thead {
      top: 0;
      background-color: #fff;
      position: sticky;
      z-index: 100;
      th {
        border-bottom: 1px solid #e1e1e1 !important;
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #bcbcbc;
    }
    th.center {
      position: relative;
      .b-status {
        width: 2px;
        height: 32px;
        position: absolute;
        left: 0px;
        top: 50%;
        background: #bcbcbc;
        border-radius: 0px 1px 1px 0px;
        transform: translate(0%, -50%);

        &.success {
          background: #00cb91;
        }
      }
    }
  }
  .table-inner {
    display: flex;
    flex: 1;
  }

  @media (max-height: 700px) {
    .table-docs {
      max-height: 100% !important;
    }
  }

  .edo-status {
    padding: 4px 12px;
    border-radius: 3px;
  }
</style>
